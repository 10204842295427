// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unity-container {
  border: none;
  background-color: black;

  border-radius: 1em;
  width: 100%;
  height: auto;
}

.Game{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 1em;
}`, "",{"version":3,"sources":["webpack://./src/screens/Game.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,uBAAuB;;EAEvB,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;;EAEnB,QAAQ;AACV","sourcesContent":[".unity-container {\n  border: none;\n  background-color: black;\n\n  border-radius: 1em;\n  width: 100%;\n  height: auto;\n}\n\n.Game{\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n  gap: 1em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
