// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Search block */

.Search {
  background-color: var(--theme-background);
  border-radius: 1em;
  padding: 1em;
}
  
.search-title {
    margin: 0;
}
  
.search-bar-container{
    margin-bottom: 1em;
}
  
.search-users-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
}
  
.search-users-user-container {
    display: flex;
    flex-direction: row;
  
    border-radius: 2em;
    background-color: var(--theme-accentBackground);
  
    padding: 1em;
    cursor: pointer;
}
  
.search-users-user-avatar {
    width: 8em;
    height: 8em;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
}
  
  .search-users-user-avatar-src {
    object-fit: contain;
    
    width: 100%;
    height: 100%;

    
  }
  
  .search-users-user-text {
    padding-left: 1em;
  }
  
  .search-users-user-description {
    color: var(--theme-background) ;
  }`, "",{"version":3,"sources":["webpack://./src/screens/Search.css"],"names":[],"mappings":"AAAA,iBAAiB;;AAEjB;EACE,yCAAyC;EACzC,kBAAkB;EAClB,YAAY;AACd;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,QAAQ;AACZ;;AAEA;IACI,aAAa;IACb,mBAAmB;;IAEnB,kBAAkB;IAClB,+CAA+C;;IAE/C,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,WAAW;IACX,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;AAClB;;EAEE;IACE,mBAAmB;;IAEnB,WAAW;IACX,YAAY;;;EAGd;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,+BAA+B;EACjC","sourcesContent":["/* Search block */\n\n.Search {\n  background-color: var(--theme-background);\n  border-radius: 1em;\n  padding: 1em;\n}\n  \n.search-title {\n    margin: 0;\n}\n  \n.search-bar-container{\n    margin-bottom: 1em;\n}\n  \n.search-users-container {\n    display: flex;\n    flex-direction: column;\n    gap: 1em;\n}\n  \n.search-users-user-container {\n    display: flex;\n    flex-direction: row;\n  \n    border-radius: 2em;\n    background-color: var(--theme-accentBackground);\n  \n    padding: 1em;\n    cursor: pointer;\n}\n  \n.search-users-user-avatar {\n    width: 8em;\n    height: 8em;\n    border-radius: 50%;\n    overflow: hidden;\n    flex-shrink: 0;\n}\n  \n  .search-users-user-avatar-src {\n    object-fit: contain;\n    \n    width: 100%;\n    height: 100%;\n\n    \n  }\n  \n  .search-users-user-text {\n    padding-left: 1em;\n  }\n  \n  .search-users-user-description {\n    color: var(--theme-background) ;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
