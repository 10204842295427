import React, { useEffect, useRef, useCallback } from 'react';
import { useState } from 'react';
import './Music.css';
import axios from "axios";
import Cookies from "js-cookie";

import { useLocalStorage } from '../hooks/useLocalStorage.js';

import ContextMenu from '../components/OptionsMenu.js';

import IconAdapter from '../icons/IconAdapter.jsx';

const Music = () => {
    const [contextMenu, setContextMenu] = useState({
        visible: false,
        position: { x: 0, y: 0 },
        options: [],
    });

    const [curPlayingId, setCurPlayingId] = useState([0,0,0])

    const [curPlaylistId, setPlaylistId] = useState(null);

    const [curPage, setCurPage] = useState('main');

    const [curOpenedPlaylistData, setCurOpenedPlaylistData] = useState(null)

    const [playlistsData, setPlaylistsData] = useState([])

    const [fieldSearch, setFieldSearch] = useState('');

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`; // Формат MM:SS
    };

    const [musicListData, setMusicListData] = useState(null);

    const [currentlyPlaying, setCurrentlyPlaying] = useState(false);
    const [curTrack, setCurTrack] = useState(null);

    const [fileSelectorText, setFileSelectorText] = useState("Выбери файл")
    const [selectedMusicFile, setSelectedMusicFile] = useState(null);

    const [uploadTrackTitle, setUploadTrackTitle] = useState("");

    const [musicSearchField, setMusicSearchField] = useState("");

    const addMediaFileInputRef = useRef();
    const playerRef = useRef();

    const refreshMusicTop = async () => {
        const formData = new FormData();
        formData.append("session", Cookies.get("authToken"));
        await axios.post(
            `https://${window.location.hostname}/api/v1/data/get_music_top`,
            formData,
            {
                headers: { "Content-Type": "multipart/form-data", },
            }
        ).then(({ data }) => {
            console.log("Music top update");
            console.warn(data)
            setMusicListData(data)
        }).catch(error => {
            const { status, data } = error.response;
            console.error("Response Status:", status, "Response Data:", data);
        })
    };

    const music_search = async () => {
        if (musicSearchField === "") {
            refreshMusicTop();
            return;
        }
        const formData = new FormData();
        formData.append("session", Cookies.get("authToken"));
        formData.append("search", musicSearchField);
        await axios.post(
            `https://${window.location.hostname}/api/v1/data/search_music`,
            formData,
            {
                headers: { "Content-Type": "multipart/form-data", },
            }
        ).then(({ data }) => {
            console.log("Music search update");
            console.warn(data)
            setMusicListData(data)
        }).catch(error => {
            const { status, data } = error.response;
            console.error("Response Status:", status, "Response Data:", data);
        })
    };

    const refresh_my_playlists = async () => {
        const formData = new FormData();
        formData.append("session", Cookies.get("authToken"));
        await axios.post(
            `https://${window.location.hostname}/api/v1/data/get_my_playlists`,
            formData,
            {
                headers: { "Content-Type": "multipart/form-data", },
            }
        ).then(({ data }) => {
            console.log("Music playlists update");
            console.log(data);
            setPlaylistsData(data)
        }).catch(error => {
            const { status, data } = error.response;
            console.error("Response Status:", status, "Response Data:", data);
        })
    };

    const create_play_list = async () => {
        const formData = new FormData();
        formData.append("session", Cookies.get("authToken"));
        formData.append("name", `Плейлист №${playlistsData.length + 1}`);
        await axios.post(
            `https://${window.location.hostname}/api/v1/data/create_playlist`,
            formData,
            {
                headers: { "Content-Type": "multipart/form-data", },
            }
        ).then(({ data }) => {
            console.log("Created playlist");
            refresh_my_playlists();
        }).catch(error => {
            const { status, data } = error.response;
            console.error("Response Status:", status, "Response Data:", data);
        })
    }

    const handleSongRightClick = (event, options) => {
        event.preventDefault();
        setContextMenu({
            visible: true,
            position: { x: event.clientX, y: event.clientY },
            options,
        });
    };

    const closeContextMenu = () => {
        setContextMenu({ ...contextMenu, visible: false });
    };

    const handlePlayTrack = (track_url, track_title) => {
        if (playerRef.current.src !== `https://${window.location.hostname}/api${track_url}`) {
            playerRef.current.src = `https://${window.location.hostname}/api${track_url}`
            playerRef.current.play();
        } else {
            if (playerRef.current.paused) {
                playerRef.current.play();
            } else {
                playerRef.current.pause();
            }
        }
        setCurrentlyPlaying(!playerRef.current.paused);
        setCurTrack(track_title);
    }

    const request_playlist = async (playlist_id) => {
        const target_playlist = playlistsData.find((p) => p.id === playlist_id);
        setCurOpenedPlaylistData(target_playlist)
        setCurPage('playlist')
    }

    const handleAddTrackToPlaylist = async (track_id, playlist_id) => {
        const formData = new FormData();
        formData.append("session", Cookies.get("authToken"));
        formData.append("track_id", track_id);
        formData.append("playlist_id", playlist_id);
        await axios.post(
            `https://${window.location.hostname}/api/v1/data/add_track_to_playlist`,
            formData,
            {
                headers: { "Content-Type": "multipart/form-data", },
            }
        ).then(({ data }) => {
            console.log("Music added to playlist");
            refresh_my_playlists();
        }).catch(error => {
            const { status, data } = error.response;
            console.error("Response Status:", status, "Response Data:", data);
        })
    }

    const handleLikeTrack = async (track_id) => {
        const formData = new FormData();
        formData.append("session", Cookies.get("authToken"));
        formData.append("music_id", track_id)
        await axios.post(
            `https://${window.location.hostname}/api/v1/data/like_music`,
            formData,
            {
                headers: { "Content-Type": "multipart/form-data", },
            }
        ).then(({ data }) => {
            console.log("Music liked");
            refreshMusicTop();
        }).catch(error => {
            const { status, data } = error.response;
            console.error("Response Status:", status, "Response Data:", data);
        })
    }

    const handleMediaAdd = async (event) => {
        const file = event.target.files[0];
        if (file.size > 20 * 1024 * 1024) {
            alert("Файл слишком большой. Максимальный размер - 20 МБ.");
            return;
        }

        setFileSelectorText(file.name);
        const arrayBuffer = await file.arrayBuffer();

        setSelectedMusicFile(file);
    };

    const uploadMusic = async (event) => {
        const formData = new FormData();
        formData.append("session", Cookies.get("authToken"));
        formData.append("music_title", uploadTrackTitle)
        formData.append("music_file", selectedMusicFile)
        await axios.post(
            `https://${window.location.hostname}/api/v1/client/upload_music`,
            formData,
            {
                headers: { "Content-Type": "multipart/form-data", },
            }
        ).then(({ data }) => {
            console.log("Music uploaded");
            refreshMusicTop();
            setSelectedMusicFile(null);
            setUploadTrackTitle("");
        }).catch(error => {
            const { status, data } = error.response;
            console.error("Response Status:", status, "Response Data:", data);
        })
    }

    const handlePlayerPauseButton = () => {
        playerRef.current.paused ? playerRef.current.play() : playerRef.current.pause();
        setCurrentlyPlaying(!playerRef.current.paused);
    }

    const [loopMode, setLoopMode] = useLocalStorage('loopMode', 0);

    const handleChangeLoopMode = () => {
        loopMode === 2 ? setLoopMode(0) : setLoopMode(loopMode + 1);
    }

    const [volume, setVolume] = useLocalStorage('volume', 0.1);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);

    const handleVolumeChange = (event) => {
        const newVolume = parseFloat(event.target.value);
        setVolume(newVolume);
        if (playerRef.current) {
            playerRef.current.volume = newVolume;
        }
    };

    const handleTimeChange = (event) => { 
        const newTime = parseFloat(event.target.value); 
        console.log(`New time: ${newTime}`); 
        setCurrentTime(newTime); 
        if (playerRef.current) { 
            console.log(`Setting player currentTime to ${newTime}`); 
            playerRef.current.currentTime = newTime; // Перемотка плеера 
        } else { 
            console.warn('playerRef.current is null or undefined'); 
        } 
    };

    const handleEnd = useCallback(() => {
        console.log('Current loopMode:', loopMode);
        const [playlistId, trackId, trackIndex] = curPlayingId;
        const target_playlist = playlistsData.find((p) => p.id === playlistId);
        if (!target_playlist)
            return;
        const next_track = target_playlist.tracks[trackIndex+1];
        switch (loopMode) {
            case 0:
                console.log("loop off");
                
                if (next_track) {
                    setCurPlayingId([target_playlist.id, next_track.id, trackIndex+1]);
                    handlePlayTrack(next_track.storage_url, next_track.title);
                }
                return;
            case 1:
                console.log("loop playlist");
                
                if (next_track) {
                    setCurPlayingId([target_playlist.id, next_track.id, trackIndex+1]);
                    handlePlayTrack(next_track.storage_url, next_track.title);
                } else {
                    const new_track = target_playlist.tracks[0];
                    setCurPlayingId([target_playlist.id, new_track.id, 0]);
                    handlePlayTrack(new_track.storage_url, new_track.title);
                }
                
                
                return;
            case 2:
                console.log("handled loop on");
                playerRef.current.play();
                return;
        }
    }, [loopMode, playlistsData, curPlayingId]);

    useEffect(() => {
        const audio = playerRef.current;

        if (audio) {
            const updateTime = () => setCurrentTime(audio.currentTime);
            const setAudioDuration = () => setDuration(audio.duration);

            audio.addEventListener("timeupdate", updateTime);
            audio.addEventListener("loadedmetadata", setAudioDuration);
            audio.addEventListener("ended", handleEnd)

            return () => {
                audio.removeEventListener("timeupdate", updateTime);
                audio.removeEventListener("loadedmetadata", setAudioDuration);
                audio.removeEventListener("ended", handleEnd)
            };
        }
    }, [handleEnd]);

    useEffect(() => {
        refreshMusicTop();
        playerRef.current.volume = volume;
        refresh_my_playlists();
    }, [])

    const music_tabs = {
        'main': <>
            <div className="music-list-holder">
                <h2 className="Music-title">Топ музыки</h2>
                <div className="music-search-bar-container">
                    <input id="music-search-bar-input" className="kitsunet-design-input-field" type="text" placeholder='' value={musicSearchField} onChange={(e) => { setMusicSearchField(e.target.value); music_search(); }} />
                </div>
                <div className="music-search-music-container">
                    {musicListData && musicListData.tracks.map((track) => (
                        <div className="music-item-holder" onContextMenu={(e) => {
                            var options = [{
                                name: 'Добавить в плейлист', submenu: []
                            }, {
                                name: 'Лайкнуть',
                                action: () => handleLikeTrack(track.id),
                            }]
                            playlistsData && playlistsData.map((playlist) => {
                                options[0].submenu.push({ name: playlist.title, action: () => handleAddTrackToPlaylist(track.id, playlist.id) })
                            })

                            handleSongRightClick(e, options)
                        }}>
                            <div className="music-item-left-holder">
                                <button className="music-item-play" onClick={() => { handlePlayTrack(track.storage_url, track.title) }}>
                                    {(playerRef.current.src === `https://${window.location.hostname}/api${track.storage_url}` & currentlyPlaying) ? (<IconAdapter icon='pauseCircleIcon' color="primary" />) : (<IconAdapter icon='playCircleIcon' color="primary" />)}
                                </button>
                                <h3>{track.title}</h3>
                            </div>
                            <div className="music-item-right-holder">
                                <h4>Рейтинг: {track.score}</h4>
                                <button className="music-item-play" onClick={() => { handleLikeTrack(track.id) }}>
                                    <IconAdapter icon='likeIcon' color="primary" />
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="music-upload-container">
                <input className='kitsunet-design-input-field' type="text" value={uploadTrackTitle} onChange={(e) => setUploadTrackTitle(e.target.value)} />
                <input
                    ref={addMediaFileInputRef}
                    type="file"
                    onChange={handleMediaAdd}
                    multiple
                    hidden
                />
                <div className="music-upload-buttons-holder">
                    <button
                        className="music-upload-add-attachment"
                        onClick={() => addMediaFileInputRef.current.click()}
                    >
                        <IconAdapter icon='attachmentIcon' color="primary" />
                    </button>
                    <h3>{fileSelectorText}</h3>
                    <button
                        className="kitsunet-design-input-button"
                        onClick={uploadMusic}
                    >
                        Загрузить
                    </button>
                </div>

            </div>
        </>,
        'playlist': <div className='music-playlist-tab'>
            {curOpenedPlaylistData && (<>
                <div className="playlist-header">
                    <img src={`https://${window.location.hostname}/api/storage/avatars/track_placeholder.png`} alt="" />
                    <div className="playlist-header-labels">
                        <h5>Плейлист</h5>
                        <h1>{curOpenedPlaylistData.title || ""}</h1>
                        <h5>{curOpenedPlaylistData.owner_name} • {curOpenedPlaylistData.tracks_count} треков</h5>
                    </div>
                </div>
                <div className="playlist-tracks-list">
                    <div className="playlist-tracks-header">
                        <div className="playlist-tracks-col playlist-tracks-col1">Название</div>
                        <div className="playlist-tracks-col playlist-tracks-col2">Лайков</div>
                        <div className="playlist-tracks-col playlist-tracks-col3">Длительность</div>
                    </div>
                    <div className="playlist-tracks-seperator"></div>
                    <div className="playlist-tracks-scroll-holder">
                        {curOpenedPlaylistData.tracks.map((track, index) => (
                            <div className="playlist-tracks-item">
                                <div className="playlist-tracks-col playlist-tracks-col1">
                                    <div className="track-icon-button-holder" onClick={() => {
                                        handlePlayTrack(track.storage_url, track.title);
                                        setCurPlayingId([curOpenedPlaylistData.id, track.id, index]);
                                    }}>
                                        <img className='playlist-track-item-icon' src={`https://${window.location.hostname}/api/storage/avatars/track_placeholder.png`} alt="" />
                                        <div className="playlist-track-icon-adapter">
                                            {(() => {
                                            const [playlistId, trackId, trackIndex] = curPlayingId; 
                                            const isPlaying = playlistId === curOpenedPlaylistData.id && trackId === track.id && trackIndex === index;
                                            return isPlaying ? (<IconAdapter icon='pauseCircleIcon' color="primary" />) : (<IconAdapter icon='playCircleIcon' color="primary" />)
                                            })()}
                                        </div>

                                    </div>
                                    <div className="playlist-track-item-text">
                                        <h4>{track.title}</h4>
                                        <h5>Трек</h5>
                                    </div>
                                </div>
                                <div className="playlist-tracks-col playlist-tracks-col2">{track.score}</div>
                                <div className="playlist-tracks-col playlist-tracks-col3">Скоро...</div>
                            </div>
                        ))}

                    </div>

                </div>
            </>)}
        </div>,
    };

    return (
        <div className="Music">
            <audio ref={playerRef} src='' hidden>
                Your browser does not support the audio element.
            </audio>

            <div className="music-search-panel">
                <input
                    className="kitsunet-design-input-field"
                    type="text"
                    placeholder="Что бы вы хотели включить?"
                    value={fieldSearch}
                    onChange={(e) => setFieldSearch(e.target.value)}
                    required
                />
            </div>

            <div className="music-main-panel">
                {/* <div className="music-main-panel-left-panel">

                </div> */}

                <div className="music-main-panel-middle-panel">
                    {
                        music_tabs[curPage] || ''
                    }
                </div>

                <div className="music-main-panel-right-panel">
                    <div className="music-right-panel-buttons-holder">
                        <button className='music-panel-curcled-buttons' onClick={() => setCurPage("main")}><IconAdapter icon='homeIcon' color="primary"></IconAdapter></button>
                        <button className='music-panel-curcled-buttons' onClick={() => create_play_list()}>+</button>
                    </div>
                    <div className="playlist-scroll-holder">
                        {playlistsData && playlistsData.map((playlist) => (
                            <div className="playlist-scroll-item" onClick={() => request_playlist(playlist.id)}>
                                <div className="playlist-item-holder">
                                    <img src={`https://${window.location.hostname}/api/storage/avatars/track_placeholder.png`} alt="" />
                                    <div className="labels-holder">
                                        <h3>{playlist.title}</h3>
                                        <h4>{playlist.owner_name} • {playlist.tracks_count} треков</h4>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="music-player-holder">
                <button className="music-player-play-button" onClick={handlePlayerPauseButton} disabled={!curTrack}>
                    {currentlyPlaying ? (<IconAdapter icon='pauseCircleIcon' color="primary" />) : (<IconAdapter icon='playCircleIcon' color="primary" />)}
                </button>
                <div className="music-player-range-title-holder">
                    <h4>{curTrack ? curTrack : 'Выберите музыку'}</h4>
                    <div className="slidecontainer">
                        <div className="duration-slider-holder">
                            {formatTime(currentTime)}
                            <input
                                type="range"
                                min="0"
                                max={duration || 0}
                                className="slider"
                                step="0.1"
                                value={currentTime}
                                onChange={handleTimeChange}
                                style={duration ? {
                                    background: `linear-gradient(to right, var(--theme-primary) ${(currentTime / duration) * 100
                                        }%, var(--theme-background) ${(currentTime / duration) * 100}%)`,
                                } : {
                                    background: `linear-gradient(to right, var(--theme-primary) ${0}%, var(--theme-background) ${0}%)`,
                                }}
                            />
                            {formatTime(duration)}
                            <button className='music-player-repeat-mode' onClick={handleChangeLoopMode}>
                                <IconAdapter icon={loopMode === 2 ? "repeatOneIcon" : "repeatIcon"} color={loopMode === 0 ? "background" : "primary"} />
                            </button>
                        </div>

                        <div className="volume-slider-holder">
                            <h4>Громкость: </h4>
                            <input
                                className="slider"
                                type="range"
                                min="0"
                                max="1"
                                step="0.01"
                                value={volume}
                                onChange={handleVolumeChange}
                                style={{
                                    background: `linear-gradient(to right, var(--theme-primary) ${volume * 100
                                        }%, var(--theme-background) ${volume * 100}%)`,
                                }}
                            />
                            {Math.round(volume * 100)}%
                        </div>
                    </div>
                </div>
            </div>
            {contextMenu.visible && (
                <ContextMenu
                    options={contextMenu.options}
                    position={contextMenu.position}
                    onClose={closeContextMenu}
                />
            )}
        </div>
    );
};

export default Music;