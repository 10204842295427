// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Media Viewer */
  
.media-viewer {
    display: none;
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: auto;
    
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 2em;
}

.media-viewer-back {
    position: absolute;
    background-color: rgba(0,0,0,0.9);

    width: 100%;
    height: 100%;
    z-index: -1;
}
   
.media-viewer-content {
    display: block;
    max-width: 80%;
    max-height: 80%;

    border-radius: 8px;
}
   
.media-viewer-close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}
   
.media-viewer-close:hover,
.media-viewer-close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/MediaViewer.css"],"names":[],"mappings":"AAAA,iBAAiB;;AAEjB;IACI,aAAa;IACb,eAAe;IACf,UAAU;IACV,WAAW;IACX,YAAY;IACZ,cAAc;;IAEd,mBAAmB;IACnB,sBAAsB;IACtB,uBAAuB;IACvB,QAAQ;AACZ;;AAEA;IACI,kBAAkB;IAClB,iCAAiC;;IAEjC,WAAW;IACX,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,cAAc;IACd,cAAc;IACd,eAAe;;IAEf,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,cAAc;IACd,eAAe;IACf,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;;IAEI,WAAW;IACX,qBAAqB;IACrB,eAAe;AACnB","sourcesContent":["/* Media Viewer */\n  \n.media-viewer {\n    display: none;\n    position: fixed;\n    z-index: 1;\n    width: 100%;\n    height: 100%;\n    overflow: auto;\n    \n    align-items: center;\n    flex-direction: column;\n    justify-content: center;\n    gap: 2em;\n}\n\n.media-viewer-back {\n    position: absolute;\n    background-color: rgba(0,0,0,0.9);\n\n    width: 100%;\n    height: 100%;\n    z-index: -1;\n}\n   \n.media-viewer-content {\n    display: block;\n    max-width: 80%;\n    max-height: 80%;\n\n    border-radius: 8px;\n}\n   \n.media-viewer-close {\n    position: absolute;\n    top: 15px;\n    right: 35px;\n    color: #f1f1f1;\n    font-size: 40px;\n    font-weight: bold;\n    transition: 0.3s;\n}\n   \n.media-viewer-close:hover,\n.media-viewer-close:focus {\n    color: #bbb;\n    text-decoration: none;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
