// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Dialog block */
  
.ConfirmationDialog {
    display: none;
  
    align-items: center;
    justify-content: center;
    position: fixed;
  
    width: 100%;
    height: 100%;
    /* top: 50%; */
    /* left: 50%; */
    /* transform: translateX(-50%); */
    /* background-color: rgba(255, 255, 255, 0.175); */
    color: var(--theme-primary);
    
    
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  
    z-index: 10000;
  }
  
  .dialog-container {
    background-color: var(--theme-accentBackground);;
    padding: 20px;
    border-radius: 1em;

    display: flex;
    flex-direction: column;
    gap: 1em;
  }
  
  .dialog-container-info h1, p {
    margin: 0;
  }
  
  .dialog-container-buttons {
    display: flex;
    flex-direction: row;
    gap: 1em;

    justify-content: end;
  }`, "",{"version":3,"sources":["webpack://./src/components/ConfirmationDialog.css"],"names":[],"mappings":"AAAA,iBAAiB;;AAEjB;IACI,aAAa;;IAEb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;;IAEf,WAAW;IACX,YAAY;IACZ,cAAc;IACd,eAAe;IACf,iCAAiC;IACjC,kDAAkD;IAClD,2BAA2B;;;IAG3B,uCAAuC;;IAEvC,cAAc;EAChB;;EAEA;IACE,+CAA+C;IAC/C,aAAa;IACb,kBAAkB;;IAElB,aAAa;IACb,sBAAsB;IACtB,QAAQ;EACV;;EAEA;IACE,SAAS;EACX;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,QAAQ;;IAER,oBAAoB;EACtB","sourcesContent":["/* Dialog block */\n  \n.ConfirmationDialog {\n    display: none;\n  \n    align-items: center;\n    justify-content: center;\n    position: fixed;\n  \n    width: 100%;\n    height: 100%;\n    /* top: 50%; */\n    /* left: 50%; */\n    /* transform: translateX(-50%); */\n    /* background-color: rgba(255, 255, 255, 0.175); */\n    color: var(--theme-primary);\n    \n    \n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);\n  \n    z-index: 10000;\n  }\n  \n  .dialog-container {\n    background-color: var(--theme-accentBackground);;\n    padding: 20px;\n    border-radius: 1em;\n\n    display: flex;\n    flex-direction: column;\n    gap: 1em;\n  }\n  \n  .dialog-container-info h1, p {\n    margin: 0;\n  }\n  \n  .dialog-container-buttons {\n    display: flex;\n    flex-direction: row;\n    gap: 1em;\n\n    justify-content: end;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
