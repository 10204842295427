// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ??? */

.main-tab-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.daily-ticks-container {
  border-radius: 1em;
  padding: 2em;
  background-color: var(--theme-background);

  display: flex;
  flex-direction: row;
  gap: 0.5em;
}

.daily-ticks-container-text {
  width: 80%;
}

.daily-ticks-container button {
  width: 20%;
}

.news-container{
  width: 50%;
  padding: 2em;
  display: flex;
  flex-direction: column;
  gap: 1em;
  background-color: var(--theme-background);
  border-radius: 1em;
}

.news-item-contaner {
  padding: 2em;
  display: flex;
  flex-direction: column;
  gap: 1em;
  background-color: var(--theme-accentBackground);
  border-radius: 1em;

}`, "",{"version":3,"sources":["webpack://./src/screens/Home.css"],"names":[],"mappings":"AAAA,QAAQ;;AAER;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,yCAAyC;;EAEzC,aAAa;EACb,mBAAmB;EACnB,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,yCAAyC;EACzC,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,+CAA+C;EAC/C,kBAAkB;;AAEpB","sourcesContent":["/* ??? */\n\n.main-tab-container {\n  display: flex;\n  flex-direction: column;\n  gap: 1em;\n}\n\n.daily-ticks-container {\n  border-radius: 1em;\n  padding: 2em;\n  background-color: var(--theme-background);\n\n  display: flex;\n  flex-direction: row;\n  gap: 0.5em;\n}\n\n.daily-ticks-container-text {\n  width: 80%;\n}\n\n.daily-ticks-container button {\n  width: 20%;\n}\n\n.news-container{\n  width: 50%;\n  padding: 2em;\n  display: flex;\n  flex-direction: column;\n  gap: 1em;\n  background-color: var(--theme-background);\n  border-radius: 1em;\n}\n\n.news-item-contaner {\n  padding: 2em;\n  display: flex;\n  flex-direction: column;\n  gap: 1em;\n  background-color: var(--theme-accentBackground);\n  border-radius: 1em;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
