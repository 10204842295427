// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App-loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 1em;
}

.loading-main-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 1em;

  /* height: 50px;
    width: 400px;
    background-color: grey;
    border-radius: 1em; */
}

.loading-main-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 0.5em;

  width: 380px;
  background-color: rgba(250, 235, 215, 0.15);
  border-radius: 1em;
}

.loading-svg {
  filter: invert(78%) sepia(7%) saturate(2583%) hue-rotate(342deg)
    brightness(104%) contrast(80%);
}

.App-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease-in-out;
}

.animate-center {
  transform: translate(-50%, -50%) scale(1.2);
}

.loading-main-holder {
  transition: opacity 0.3s ease-in-out;
}

.animate-center .loading-main-holder {
  opacity: 0;
}
`, "",{"version":3,"sources":["webpack://./src/screens/Placeholder.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;;EAEnB,QAAQ;AACV;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;;EAEnB,QAAQ;;EAER;;;yBAGuB;AACzB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;;EAEnB,cAAc;;EAEd,YAAY;EACZ,2CAA2C;EAC3C,kBAAkB;AACpB;;AAEA;EACE;kCACgC;AAClC;;AAEA;EACE,eAAe;EACf,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,gCAAgC;AAClC;;AAEA;EACE,2CAA2C;AAC7C;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".App-loading {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n  gap: 1em;\n}\n\n.loading-main-holder {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n  gap: 1em;\n\n  /* height: 50px;\n    width: 400px;\n    background-color: grey;\n    border-radius: 1em; */\n}\n\n.loading-main-item {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n\n  padding: 0.5em;\n\n  width: 380px;\n  background-color: rgba(250, 235, 215, 0.15);\n  border-radius: 1em;\n}\n\n.loading-svg {\n  filter: invert(78%) sepia(7%) saturate(2583%) hue-rotate(342deg)\n    brightness(104%) contrast(80%);\n}\n\n.App-loading {\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  transition: all 0.3s ease-in-out;\n}\n\n.animate-center {\n  transform: translate(-50%, -50%) scale(1.2);\n}\n\n.loading-main-holder {\n  transition: opacity 0.3s ease-in-out;\n}\n\n.animate-center .loading-main-holder {\n  opacity: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
