import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ConfirmationDialogProvider } from "./context/ConfirmationDialogContext";
import reportWebVitals from "./reportWebVitals";
import { NotificationProvider } from "./context/NotificationContext";


import { SocketProvider } from "./context/SocketContext";

import { ThemeProvider } from "./context/ThemeContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <SocketProvider>
      <NotificationProvider>
        <ConfirmationDialogProvider>
            <ThemeProvider>
              <App/>
            </ThemeProvider>
        </ConfirmationDialogProvider>
      </NotificationProvider>
    </SocketProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
