// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Notification block */
  
.notification {
    display: none;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ffaa49;
    color: white;
    margin-top: 1em;
    padding: 20px;
    border-radius: 1em;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  
    z-index: 10000;
}
  
.notification h1, p {
    margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/NotificationComponent.css"],"names":[],"mappings":"AAAA,uBAAuB;;AAEvB;IACI,aAAa;IACb,eAAe;IACf,MAAM;IACN,SAAS;IACT,2BAA2B;IAC3B,yBAAyB;IACzB,YAAY;IACZ,eAAe;IACf,aAAa;IACb,kBAAkB;IAClB,uCAAuC;;IAEvC,cAAc;AAClB;;AAEA;IACI,SAAS;AACb","sourcesContent":["/* Notification block */\n  \n.notification {\n    display: none;\n    position: fixed;\n    top: 0;\n    left: 50%;\n    transform: translateX(-50%);\n    background-color: #ffaa49;\n    color: white;\n    margin-top: 1em;\n    padding: 20px;\n    border-radius: 1em;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);\n  \n    z-index: 10000;\n}\n  \n.notification h1, p {\n    margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
