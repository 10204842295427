import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";

import "./App.css";

import Main from "./Main";
import Auth from "./Auth";
import NotificationComponent from "./components/NotificationComponent";
import Placeholder from "./screens/Placeholder";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  function updateSvgColor(color) {
    const svgs = document.querySelectorAll('svg');
    svgs.forEach(svg => {
      const path = svg.querySelector('path');
      if (path) {
        path.setAttribute('fill', color);
      }
    });
  }

  useEffect(() => {
    const token = Cookies.get("authToken");
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  useEffect(() => {

    updateSvgColor(getComputedStyle(document.documentElement).getPropertyValue('--theme-primary'));

  }, []);

  return (
    <div className="App">
      <div className="background-gradient"></div>
      <div class="stars">
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
      </div>
      <div class="area" >
        <ul class="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div >
      <NotificationComponent />
      {isAuthenticated ? <Placeholder><Main /></Placeholder> : <Auth />}
      {/* {isAuthenticated ? <Main /> : <Auth />} */}
    </div>
  );
};

export default App;
