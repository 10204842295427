// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Friends block */

.friends-container {
    display: flex;
    flex-direction: column;
    gap: 1em;

    padding: 1em;
    border-radius: 1em;
    background-color: var(--theme-background);
  }
  
  .friends-main-content-container {
    display: flex;
    flex-direction: row;
    gap: 1em;
  }
  
  .friends-profile-container {
    /* background-color: #555; */
    width: 80%;

    border-radius: 1em;
  
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
  
  .friends-search-container {
    box-sizing: border-box;
    padding: 1em;
  }

  .friends-search-users-user-container {
    display: flex;
    flex-direction: row;
    padding: 1em;
    border-radius: 1em;
    background-color: var(--theme-accentBackground);
  }

  .pallete {
    color: var(--theme-secondary);
    color: var(--theme-primary);
    color: var(--theme-background);
    color: var(--theme-accentBackground);
  }
  
  .friends-all-incoming-outgouig-container {
    padding: 1em;
  
    background-color: var(--theme-accentBackground);
    border-radius: 1em;
    width: 20%;
    margin-left: 0em;
  
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
  
  .friends-all-incoming-outgouig-container h3 {
    color: var(--theme-background);
  }`, "",{"version":3,"sources":["webpack://./src/screens/Friends.css"],"names":[],"mappings":"AAAA,kBAAkB;;AAElB;IACI,aAAa;IACb,sBAAsB;IACtB,QAAQ;;IAER,YAAY;IACZ,kBAAkB;IAClB,yCAAyC;EAC3C;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,QAAQ;EACV;;EAEA;IACE,4BAA4B;IAC5B,UAAU;;IAEV,kBAAkB;;IAElB,aAAa;IACb,sBAAsB;IACtB,QAAQ;EACV;;EAEA;IACE,sBAAsB;IACtB,YAAY;EACd;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;IAClB,+CAA+C;EACjD;;EAEA;IACE,6BAA6B;IAC7B,2BAA2B;IAC3B,8BAA8B;IAC9B,oCAAoC;EACtC;;EAEA;IACE,YAAY;;IAEZ,+CAA+C;IAC/C,kBAAkB;IAClB,UAAU;IACV,gBAAgB;;IAEhB,aAAa;IACb,sBAAsB;IACtB,QAAQ;EACV;;EAEA;IACE,8BAA8B;EAChC","sourcesContent":["/* Friends block */\n\n.friends-container {\n    display: flex;\n    flex-direction: column;\n    gap: 1em;\n\n    padding: 1em;\n    border-radius: 1em;\n    background-color: var(--theme-background);\n  }\n  \n  .friends-main-content-container {\n    display: flex;\n    flex-direction: row;\n    gap: 1em;\n  }\n  \n  .friends-profile-container {\n    /* background-color: #555; */\n    width: 80%;\n\n    border-radius: 1em;\n  \n    display: flex;\n    flex-direction: column;\n    gap: 1em;\n  }\n  \n  .friends-search-container {\n    box-sizing: border-box;\n    padding: 1em;\n  }\n\n  .friends-search-users-user-container {\n    display: flex;\n    flex-direction: row;\n    padding: 1em;\n    border-radius: 1em;\n    background-color: var(--theme-accentBackground);\n  }\n\n  .pallete {\n    color: var(--theme-secondary);\n    color: var(--theme-primary);\n    color: var(--theme-background);\n    color: var(--theme-accentBackground);\n  }\n  \n  .friends-all-incoming-outgouig-container {\n    padding: 1em;\n  \n    background-color: var(--theme-accentBackground);\n    border-radius: 1em;\n    width: 20%;\n    margin-left: 0em;\n  \n    display: flex;\n    flex-direction: column;\n    gap: 1em;\n  }\n  \n  .friends-all-incoming-outgouig-container h3 {\n    color: var(--theme-background);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
